@import './app-variables';

.mat-button-wrapper {
  text-transform: uppercase;
}
.mat-card {
  padding: 16px 18px !important;
}

mat-ink-bar.mat-ink-bar {
  height: 3px;
}

.mat-dialog-content {
  p {
    color: $foundational-color-7;
    font-size: 16px;
  }

  .mat-input-element,
  .mat-select-value-text * {
    color: $foundational-color-10;
    font-size: $base-font-size-web;
  }
  .mat-error span,
  .mat-error div,
  .mat-error p {
    font-size: $text-size-body-4;
    font-weight: $text-weight-rg;
    color: $text-color-error;
  }
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 100%, transparent 0%);
  background-size: unset;
  background-repeat: no-repeat;
}

.mat-input-element:disabled {
  color: $text-color-dark-4;
}

.mat-form-field-subscript-wrapper {
  margin-top: 4px !important;
}

.mat-warnings {
  .mat-form-field-ripple,
  .mat-form-field-underline {
    background-color: $xpo-warning-color !important;
  }

  .mat-form-field-label {
    color: $xpo-warning-color !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
// Due to inconsistencies between ngx-ltl and ngx-core css libraries, we need to override some styles in order to achieve the expected result.
// this will happen for the role switcher dialog
mat-dialog-container {
  // position: relative;
  xpo-dialog-close-button {
    position: relative;
    top: -10px;
    right: -5px;
    float: right;
    button {
      display: flex !important;
      justify-content: center;
      color: black !important;
      .mat-button-ripple {
        &:hover {
          color: unset !important;
        }
      }
      mat-icon {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
