$base-font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$base-font-size-web: 14px;
$base-rem: 0.07143; // 1px based on 14px base size

//********************************************APPLICATION COLORS*********************************************

// BRAND COLORS
$brand-color-1: #cc0000;
$brand-color-2: #1c1e1c;
$brand-color-3: #ffffff;
$brand-color-4: #333333;
$brand-color-5: #858585;
$brand-color-6: #dfdfdf;

// FOUNDATIONAL PALETTE
$foundational-color-1: #fafafa;
$foundational-color-2: #f5f5f5;
$foundational-color-3: #eeeeee;
$foundational-color-4: #e0e0e0;
$foundational-color-5: #bdbdbd;
$foundational-color-6: #9e9e9e;
$foundational-color-7: #757575;
$foundational-color-8: #616161;
$foundational-color-9: #424242;
$foundational-color-10: #212121;

// ACTION COLORS
$action-color-1: #0091ea;
$action-color-2: #80d8ff;
$action-color-3: $foundational-color-7;

// ACCENT COLORS
$accent-color-1: #00c853;
$accent-color-2: #64dd17;
$accent-color-3: #aeea00;
$accent-color-4: #0091ea;
$accent-color-5: #00b8d4;
$accent-color-6: #00bfa6;
$accent-color-7: #6200ea;
$accent-color-8: #304ffe;
$accent-color-9: #2962ff;
$accent-color-10: #d50000;
$accent-color-11: #c51162;
$accent-color-12: #aa00ff;
$accent-color-13: #dd2c00;
$accent-color-14: #ff6d00;
$accent-color-15: #ffd600;

$overlay-background-color: rgba(255, 255, 255, 0.3);
$background-color-dark-1: rgba(85, 85, 85, 1);
$background-color-dark-2: rgba(0, 0, 0, 1);
$background-color-light-1: rgba(255, 255, 255, 1);
$divider-color: rgba(0, 0, 0, 0.12);

// INTENTIONAL COLOR
$color-error: $accent-color-10;
$app-background: $foundational-color-1;
$nav-background: $brand-color-3;

// LAYOUT
$content-panel-vertical-padding: 20px;
$content-panel-horizontal-padding: 20px;
$content-panel-spacing: 7px;
$form-item-spacing: 16px;

// SCREEN SIZES for media queries
$laptop-width: 1200px;
$tablet-landscape-width: 1100px;
$tablet-portrait-width: 800px;
$phone-portrait-width: 600px;

// Breakpoints from Design
$bp-xs: 20rem; // 320px
$bp-sm: 30rem; // 480px
$bp-md: 60rem; // 960px
$bp-lg: 80rem; // 1280px

// Less Than Size
$bp-lt-xs: 'screen and (max-width: #{$bp-xs - $base-rem})';
$bp-lt-sm: 'screen and (max-width: #{$bp-sm - $base-rem})';
$bp-lt-md: 'screen and (max-width: #{$bp-md - $base-rem})';
$bp-lt-lg: 'screen and (max-width: #{$bp-lg - $base-rem})';

// Greater Than Size
$bp-gt-xs: 'screen and (min-width: #{$bp-xs})';
$bp-gt-sm: 'screen and (min-width: #{$bp-sm})';
$bp-gt-md: 'screen and (min-width: #{$bp-md})';
$bp-gt-lg: 'screen and (min-width: #{$bp-lg})';

// CONTENT
$content-width: 1280px;
$content-padding: 24px;

// LAYOUT
$header-height: 52px;
$toolbar-height: 0px;
$navbar-height: 50px;
$footer-height: 60px;
$split-area-gutter: 24px;
$dialog-width: 450px;
$dialog-height: 100px;
$region-height: 20px;

$content-top: $toolbar-height + $navbar-height;
$content-top-non-prod: $content-top;
$content-height: calc(100% - #{$content-top + $footer-height});
$content-height-no-navbar: calc(100% - #{$content-top - $navbar-height + $footer-height});
$content-height-non-prod: calc(100% - #{$content-top-non-prod + $footer-height});
$content-height-no-navbar-non-prod: calc(100% - #{$content-top-non-prod - $navbar-height + $footer-height});

$registration-top-bottom-height: $header-height + $navbar-height + $footer-height;
$registration-top-bottom-height-non-prod: $registration-top-bottom-height + $region-height;
$vh100: calc(var(--vh, 1vh) * 100);
$vh70: calc(var(--vh, 1vh) * 70);
$vh50: calc(var(--vh, 1vh) * 50);

// LOGO
$svg-logo-width-1: 130px;

// TOOLBAR
$toolbar-color: #f2f2f2;

// TEXT SIZE AND WEIGHT
$text-weight-rg: 400;
$text-weight-md: 500;
$text-weight-hv: 700;

$text-size-header-1: 24px;
$text-size-header-2: 20px;
$text-size-header-3: 18px;

$text-size-body-1: 16px;
$text-size-body-2: 14px;
$text-size-body-3: 12px;
$text-size-body-4: 10px;

$text-size-jumbo: 36px;

// TEXT COLOR
$text-color-dark-1: rgba(0, 0, 0, 1);
$text-color-dark-2: rgba(0, 0, 0, 0.87);
$text-color-dark-3: rgba(0, 0, 0, 0.7);
$text-color-dark-4: rgba(0, 0, 0, 0.54);
$text-color-dark-5: rgba(0, 0, 0, 0.37);

$text-color-light-1: rgba(255, 255, 255, 1);
$text-color-light-2: rgba(255, 255, 255, 0.87);
$text-color-light-3: rgba(255, 255, 255, 0.7);
$text-color-light-4: rgba(255, 255, 255, 0.54);

$text-color-link-1: rgba(0, 145, 234, 1);
$text-color-valid: #00c853;
$text-color-error: #d50000;
$text-color-warning: #ff6d00;

$highlight-color: #ffffe1;
$disabled-checkbox-color: #ff6d00;

// WARNINGS/ERRORS
$xpo-warning-font-size: 12px;
$xpo-warning-color: #ff6d00;
$xpo-warning-background: #fff1e6;

// SLIDE TOGGLE
$slide-toggle-1: rgba(0, 145, 234, 1);
$slide-toggle-2: rgba(0, 145, 234, 0.5);

// STATUS CHIPS COLORS
$status-submitted: #78909c;
$status-unsubmitted: $xpo-warning-color;
$status-under-review: #737373;
$status-declined: $accent-color-10;
$status-approved: $accent-color-1;
$status-customer-cancelled: #303f9f;
$status-rebuttal-received: #c2185b;
$status-pending-approval: #90a4ae;
$status-examiner-pending-approval: #78909c;
$status-manager-pending-approval: #607d8b;
$status-director-pending-approval: #546e7a;
$status-svp-of-ops-pending-approval: #455a64;
$status-ceo-pending-approval: #37474f;
$status-president-pending-approval: #263238;
