/* You can add global styles to this file, and also import other style files */

@import 'app-variables';

// Add some css grid debugging (thanks Peter!)
// * * {
//   background-color: rgba(0, 250, 0, 0.04)
// }
.xpo-badge {
  height: 15px;
  width: 15px;
  text-align: center;
  background-color: black;
  color: white;
  border-radius: 50%;
  font-size: $text-size-body-3;
  font-weight: $text-weight-md;
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: space-around;
  right: 5px;
}

.xpo-badge-override {
  height: 15px;
  width: 15px;
  text-align: center;
  background-color: black;
  color: white;
  border-radius: 50%;
  font-size: $text-size-body-3;
  font-weight: $text-weight-md;
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: space-around;
  right: 5px;
  animation: color_change 1s infinite alternate;
}
@keyframes color_change {
  0% {
    background-color: blue;
  }
  25% {
    background-color: orange;
  }
  50% {
    background-color: yellow;
  }
  75% {
    background-color: black;
  }
  100% {
    background-color: red;
  }
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: $base-font-family;
  font-size: $base-font-size-web;

  @media screen and (max-width: $tablet-portrait-width) {
    height: calc(100vh - 60px); // fallback for old iOS versions
    min-height: calc(100vh - 60px); // fallback for old iOS versions
    max-height: calc(100vh - 60px); // fallback for old iOS versions

    height: $vh100;
    min-height: $vh100;
    max-height: $vh100;
    overflow: hidden;
  }
}
fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

a {
  color: $action-color-1;
  text-decoration: none;
}

form {
  .read-only-field {
    &__container {
      padding: 0 4px;
      margin-top: -4px;
    }

    &__label {
      margin-bottom: 8px;
      color: $foundational-color-7;
      font-size: 12px;
    }

    &__value {
      // use if needed
    }
  }
}
.subtotals {
  font-weight: $text-weight-hv;
  font-size: 16px;
  color: $text-color-dark-2;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

@media screen and (max-width: $laptop-width) {
  .mat-form-field-type-mat-select {
    max-width: 90vw;
  }
}
@media screen and (max-width: $tablet-portrait-width) {
  .resp-w50 {
    width: 50% !important;
  }
  .resp-w100 {
    width: 100% !important;
  }
  .resp-mb20 {
    margin-bottom: 20px !important;
  }
  .resp-mb10 {
    margin-bottom: 10px !important;
  }
  .resp-fieldset {
    * {
      max-width: 90vw;
    }
    min-inline-size: initial;
  }
  .mat-checkbox-inner-container {
    margin-top: 3px !important;
  }
  .mat-checkbox-layout {
    white-space: normal !important;
  }
  .mat-checkbox-label {
    line-height: initial !important;
    margin-top: 3px;
  }
}
